import React from 'react';
import { Modal } from 'react-bootstrap';
import './styles.css';
import TableCCLA from '../TableCCLA/TableCCLA';

export default function ModalCCLA({
  showModal = false,
  data,
  closeModal = () => {},
}) {

  const handleNavigateToPrint = (processo) => {
    window.open(`/cadeia_custodia/${processo.licenca}/${processo.id}/imprimir`);
  }

  return (
    <Modal
      dialogClassName="modal-80w"
      onHide={closeModal}
      scrollable
      show={showModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            Cadeia de custódia de licenciamento ambiental - CCLA
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableCCLA data={data} />
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-success"
          onClick={() => handleNavigateToPrint(data[0])}
          title="Imprimir"
          type="button"
        >
          <i className="fas fa-print" />
        </button>
      </Modal.Footer>
    </Modal>
  );
}
