import React, { useState, useEffect } from 'react';
import { BoxCadPFisica } from './styles';
import { FormCad } from './components';
import Swal from 'sweetalert2';
import { initialData } from './components/FormCad/InitialData';
import schema from './components/FormCad/schemaValidations';
import { useDispatch, useSelector } from 'react-redux';
import removeMask from '../../utils/removeMask';
import { storeTecnicoProfissional } from '~/store/modules/tecnico_profissional/action'

export default function CadTecnicoProfissional({ history }) {
  const [pessoa, setPessoa] = useState(initialData);
  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { status_response, errors } = useSelector(({ TecnicoProfissionalReducer }) => TecnicoProfissionalReducer);

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
      Swal.fire('Error', 'Error ao salvar os dados', 'error');
    }
  }, [errors]);

  useEffect(() => {
    if (status_response === 201) {
      Swal.fire(
        'Sucesso',
        'Cadastro efetuado com sucesso!',
        'success'
      ).then((result) => {
        if (result.isConfirmed) {
          history.push('/login');
        }
      });
      
    }
  }, [status_response, history, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!schema.isValidSync(pessoa)) {
      let yup_errors = {};
      schema.validate(pessoa, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
        const form = new FormData();
        const pessoaTescnicoWithoutSpecialCharacters = pessoa;
        pessoaTescnicoWithoutSpecialCharacters.cpf_cnpj = removeMask(
          pessoaTescnicoWithoutSpecialCharacters.cpf_cnpj
        );
        pessoaTescnicoWithoutSpecialCharacters.numero_whatsapp = removeMask(
          pessoaTescnicoWithoutSpecialCharacters.numero_whatsapp
        );
        pessoaTescnicoWithoutSpecialCharacters.cep = removeMask(
          pessoaTescnicoWithoutSpecialCharacters.cep
        );
        pessoaTescnicoWithoutSpecialCharacters.telefone = removeMask(
          pessoaTescnicoWithoutSpecialCharacters.telefone
        );
        for (let key in pessoaTescnicoWithoutSpecialCharacters) {
          form.append(key, pessoaTescnicoWithoutSpecialCharacters[key]);
        }
        await dispatch(storeTecnicoProfissional(form));

    }
  }

  return (
    <BoxCadPFisica className="d-flex d-flex-row box-base-green">
      <h3> CADASTRO TÉCNICO CONSULTOR  (CTC) </h3>
      <FormCad
        errors={errorsValidate}
        onHandleSubmit={handleSubmit}
        pessoa={pessoa}
        setErrors={setErrorsValidate}
        setPessoa={setPessoa}
      />
    </BoxCadPFisica>
  );
}
