import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as Processo from '~/services/Processo';
import * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadProcessosWorker({ params }) {
  try {
    const response = yield call(Processo.load, params);
    yield put(actions.getProcessos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadDistribuicaoWorker({ params }) {
  try {
    const response = yield call(Processo.load_distribuicao, params);
    yield put(actions.getProcessos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadAutorizacaoWorker({ params }) {
  try {
    const response = yield call(Processo.load_autorizacao, params);
    yield put(actions.getProcessos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadListaAnaliseWorker({ params }) {
  try {
    const response = yield call(Processo.load_lista_analise, params);
    yield put(actions.getProcessos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadTramitesProcessoWorker({ id }) {
  try {
    const response = yield call(Processo.load_tramites, id);
    yield put(actions.getTramitesProcesso(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadCondicionantesProcessoWorker({ id }) {
  try {
    const response = yield call(Processo.load_condicionantes, id);
    yield put(actions.getCondicionantesProcesso(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* updateCondicionantesProcessoWorker({ id, data }) {
  try {
    const response = yield call(Processo.update_condicionantes, id, data);
    yield put(actions.getCondicionantesProcesso(response));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showProcessoWorker({ id, params }) {
  try {
    const response = yield call(Processo.show, id, params);
    yield put(actions.getProcesso(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* enviarParaApiaWorker({ id }) {
  try {
    const response = yield call(Processo.enviar_para_apia, id);
    yield put(actions.getProcesso(response));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* enviarParaDistribuicaoWorker({ id }) {
  try {
    const response = yield call(Processo.enviar_para_distribuicao, id);
    yield put(actions.getProcesso(response));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* enviarParaAutorizacaoWorker({ id }) {
  try {
    const response = yield call(Processo.enviar_para_autorizacao, id);
    yield put(actions.getProcesso(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* updateProcessoWorker({ processo, id }) {
  try {
    const response = yield call(Processo.update, id, processo);
    yield put(actions.getProcesso(response));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* enviarParaAnalistaWorker({ id, data }) {
  try {
    const response = yield call(Processo.enviar_para_analista, id, data);
    yield put(actions.getProcesso(response));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* gerarCobrancaWorker({ id }) {
  try {
    const response = yield call(Processo.gerar_cobranca, id);
    yield put(actions.getProcesso(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* addAtividadeTipoLicencaWorker({ id, data }) {
  try {
    const response = yield call(Processo.add_atividade_tipo_licenca, id, data);
    yield put(actions.getProcesso(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* addPessoaResponsavelTecnicoWorker({ id, data }) {
  try {
    const response = yield call(
      Processo.add_pessoa_responsavel_tecnico,
      id,
      data
    );
    yield put(actions.getProcesso(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* finalizarProcessoWorker({ id, data }) {
  try {
    const response = yield call(Processo.finalizar_processo, id, data);
    yield put(actions.getProcesso(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* enviarLicencaWorker({ id }) {
  try {
    const response = yield call(Processo.enviar_licenca_email, id);
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ save: true }));
    yield put(getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* storeProcessoWorker({ processo }) {
  try {
    const response = yield call(Processo.store, processo);
    yield put(actions.getProcesso(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ save: true }));
    yield put(getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* getBoletoWorker({ info }) {
  try {
    const response = yield call(Processo.getBoleto, info);
    // console.log('response gerar boleto: ', response);
    yield put(actions.setBoleto(response.data.url_boleto));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* removerProcessoWorker({ id }) {
  try {
    yield call(Processo.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* listaProcesso() {
  try {
    const list = yield call(Processo.lista);
    yield put(actions.listaProcessosPorUsuario(list));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadProcessoReferenciaWorker({ processo_id }) {
  try {
    const response = yield call(Processo.load_process_ref, processo_id);
    yield put(actions.processosRef(response.data));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadProcessoCCLAWorker({ protocolo }) {
  try {
    const response = yield call(Processo.get_processo_ccla, protocolo);
    yield put(actions.getProcessoCCLAList(response.data));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* watcherProcesso() {
  yield takeLatest(actionType.LOAD_PROCESSOS, loadProcessosWorker);
  yield takeLatest(actionType.STORE_PROCESSO, storeProcessoWorker);
  yield takeLatest(actionType.SHOW_PROCESSO, showProcessoWorker);
  yield takeLatest(actionType.UPDATE_PROCESSO, updateProcessoWorker);
  yield takeLatest(actionType.REMOVER_PROCESSO, removerProcessoWorker);
  yield takeLatest(
    actionType.ADD_ATIVIDADE_TIPO_LICENCA,
    addAtividadeTipoLicencaWorker
  );
  yield takeLatest(
    actionType.ADD_PESSOA_RESPONSAVEL_TECNICO,
    addPessoaResponsavelTecnicoWorker
  );
  yield takeLatest(actionType.ENVIAR_PARA_APIA, enviarParaApiaWorker);
  yield takeLatest(
    actionType.ENVIAR_PARA_DISTRIBUICAO,
    enviarParaDistribuicaoWorker
  );
  yield takeLatest(
    actionType.LOAD_TRAMITES_PROCESSO,
    loadTramitesProcessoWorker
  );
  yield takeLatest(actionType.GERAR_COBRANCA, gerarCobrancaWorker);
  yield takeLatest(
    actionType.LOAD_PROCESSO_DISTRIBUICAO,
    loadDistribuicaoWorker
  );
  yield takeLatest(actionType.ENVIAR_PARA_ANALISTA, enviarParaAnalistaWorker);
  yield takeLatest(
    actionType.LOAD_PROCESSO_LISTA_ANALISE,
    loadListaAnaliseWorker
  );
  yield takeLatest(
    actionType.LOAD_CONDICIONANTES_PROCESSO,
    loadCondicionantesProcessoWorker
  );
  yield takeLatest(
    actionType.UPDATE_CONDICIONANTES_PROCESSO,
    updateCondicionantesProcessoWorker
  );
  yield takeLatest(actionType.GET_BOLETO, getBoletoWorker);
  yield takeLatest(
    actionType.ENVIAR_PARA_AUTORIZACAO,
    enviarParaAutorizacaoWorker
  );
  yield takeLatest(actionType.LOAD_PROCESSO_AUTORIZACAO, loadAutorizacaoWorker);
  yield takeLatest(actionType.FINALIZAR_PROCESSO, finalizarProcessoWorker);
  yield takeLatest(actionType.ENVIAR_LICENCA, enviarLicencaWorker);
  yield takeLatest(actionType.LISTA_PROCESSOS, listaProcesso);
  yield takeLatest(
    actionType.GET_PROCESSOS_POR_REFERENCIA,
    loadProcessoReferenciaWorker
  );
  yield takeLatest(actionType.GET_PROCESSOS_CCLA, loadProcessoCCLAWorker);
}

export default function* saga() {
  yield all([watcherProcesso()]);
}
