import React from 'react';
import styles from './styles';
import {  Table, Row, Col } from '~/components/PDF';
import formatForMask from '~/utils/formatForMask';
import formatCep from '~/utils/formatCep';
import { formatData } from '~/utils/formatDataHora';

function InfoTable({process}) {

    return (
        <Table>
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Processo
          </Col>
          <Col>
            {process.protocolo}
          </Col>
        </Row>
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Empreendimento
          </Col>
          <Col>
            {`${process?.pessoa?.nome} - ${process?.pessoa?.cnpj ? formatForMask(process?.pessoa?.cnpj, '##.###.###/####-##') : ''}${process?.pessoa?.cpf ? formatForMask(process?.pessoa?.cpf, '###.###.###-##') : ''}`}
          </Col>
        </Row>
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Porte Empreendimento
          </Col>
          <Col>
            {process.porte_empreendimento && process.porte_empreendimento?.nome}
          </Col>
        </Row>
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Local
          </Col>
          <Col>
            {process?.municipio?.nome} - {process?.estado?.uf}
          </Col>
        </Row>
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Atividade
          </Col>
          <Col>
            {process?.atividade && process.atividade.nome}
          </Col>
        </Row>
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Endereço da Atividade
          </Col>
          <Col>
          {process.logradouro} - {process.bairro} -{' '}
          {process.numero_endereco
            ? process.numero_endereco
            : 'Nº não informado'}{' '}
          - {formatCep(process.cep)} -{' '}
          {process.complemento ? process.complemento : 'Sem complemento.'}
          </Col>
        </Row>
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Tipo de Licença
          </Col>
          <Col>
            {process?.tipo_licenca?.nome}
          </Col>
        </Row>
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Técnico Consultor
          </Col>
          <Col>
          {process?.pessoa_responsavel_tecnico &&
            process?.pessoa_responsavel_tecnico?.responsavel_tecnico &&
            process?.pessoa_responsavel_tecnico?.responsavel_tecnico
              ?.pessoa_tecnico_profissional &&
            process.pessoa_responsavel_tecnico?.responsavel_tecnico
              ?.pessoa_tecnico_profissional.nome}{' '}
          -{' '}
          {process?.pessoa_responsavel_tecnico &&
          process?.pessoa_responsavel_tecnico?.responsavel_tecnico &&
          process?.pessoa_responsavel_tecnico?.responsavel_tecnico
            ?.pessoa_tecnico_profissional &&
          process?.pessoa_responsavel_tecnico?.responsavel_tecnico
            ?.pessoa_tecnico_profissional?.cnpj
            ? formatForMask(
                process?.pessoa_responsavel_tecnico?.responsavel_tecnico
                  ?.pessoa_tecnico_profissional?.cnpj,
                '##.###.###/####-##'
              )
            : '' -
              process?.pessoa_responsavel_tecnico?.responsavel_tecnico
                ?.pessoa_tecnico_profissional?.cpf
            ? formatForMask(
                process?.pessoa_responsavel_tecnico?.responsavel_tecnico
                  ?.pessoa_tecnico_profissional?.cpf,
                '###.###.###-##'
              )
            : ''}
          </Col>
        </Row>
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Endereço da Empresa
          </Col>
          <Col>
          {process?.pessoa?.logradouro}, {process?.pessoa?.bairro} - {formatCep(process?.pessoa?.cep)} - {process?.pessoa?.cidade}
          </Col>
        </Row>
        {process?.tramites?.some(item => item.tipo === "distribuicao") && (
        <Row header>
          <Col style={[styles.semibold, { width: '40%', fontSize: '10px' }]}>
            Data de Protocolo
          </Col>
          <Col>
            {process?.tramites
              .filter(item => item.tipo === "distribuicao")
              .map(item => formatData(item.created_at))}
          </Col>
        </Row>
      )}
      </Table>
    );
}

export default InfoTable;