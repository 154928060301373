import styled from 'styled-components';

export const TableCustom = styled.table`
  border: 1px solid #ddd;
  width: 100%;
  &:nth-child(even){
    background-color: ${props => props.striped ? '#eeeeee' : 'none'};
  }
`;

export const TdCustom = styled.td`
  border: 1px solid #ddd;
  width: 50%;
`;

export const ThCustom = styled.th`
  border: 1px solid #ddd;
  width: 50%;
`;
