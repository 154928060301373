import * as Yup from 'yup';
import { validateCNPJ, validateCPF, validatePhone, validateCep } from 'validations-br';
// import {
//   format as formatCPF,
//   validate as validateCPF,
// } from 'gerador-validador-cpf';

// const validadarCNPJ = (cnpj) => {
//   cnpj = cnpj.replace(/[^\d]+/g,'');
//
//   if(cnpj === '') return false;
//
//   if (cnpj.length !== 14)
//     return false;
//
//   // Elimina CNPJs invalidos conhecidos
//   if (cnpj === '00000000000000' ||
//         cnpj === '11111111111111' ||
//         cnpj === '22222222222222' ||
//         cnpj === '33333333333333' ||
//         cnpj === '44444444444444' ||
//         cnpj === '55555555555555' ||
//         cnpj === '66666666666666' ||
//         cnpj === '77777777777777' ||
//         cnpj === '88888888888888' ||
//         cnpj === '99999999999999')
//     return false;
//
//   // Valida DVs
//   let tamanho = cnpj.length - 2
//   let numeros = cnpj.substring(0,tamanho);
//   let digitos = cnpj.substring(tamanho);
//   let soma = 0;
//   let pos = tamanho - 7;
//   for (let i = tamanho; i >= 1; i =i+1) {
//     soma += numeros.charAt(tamanho - i) * pos--;
//     if (pos < 2)
//       pos = 9;
//   }
//   let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
//   if (String(resultado) !== digitos.charAt(0))
//     return false;
//
//   tamanho = tamanho + 1;
//   numeros = cnpj.substring(0,tamanho);
//   soma = 0;
//   pos = tamanho - 7;
//   for (let i = tamanho; i >= 1; i--) {
//     soma += numeros.charAt(tamanho - i) * pos--;
//     if (pos < 2)
//       pos = 9;
//   }
//   resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
//   if (String(resultado) !== digitos.charAt(1))
//     return false;
//
//   return true;
// };

// const testCPF = input => {
//   const cpf = formatCPF(input);
//   return validateCPF(cpf);
// };

const schema = Yup.object().shape({
  nome: Yup.string().required('Razão social obrigatório'),
  nome_fantasia: Yup.string().required('Nome fantasia obrigatório'),
  cnpj: Yup.string()
    .test(
      'is-cnpj',
      'CNPJ não é valido',
      (value) => validateCNPJ(value)
    )
    .required('CNPJ é obrigatório'),
  anexo_cnpj: Yup.string().required('Anexo do CNPJ obrigatório'),
  certidao_simplificada_jc: Yup.boolean().required('Certidão simplificada da junta comercial é obrigatório'),
  anexo_certidao_simplificada_jc: Yup.string()
    .when('certidao_simplificada_jc', {
      is: true,
      then: Yup.string().required('Anexo da certidão simplificada da junta comercial é obrigatório')
    }),
  logradouro: Yup.string().required('Logradouro obrigatório'),
  anexo_endereco: Yup.string().required('Anexo do endereço obrigatório'),
  numero_endereco: Yup.string().required('Nº do endereço obrigatório'),
  cidade: Yup.string().required('Cidade obrigatória'),
  bairro: Yup.string().required('bairro obrigatório'),
  cep: Yup.string()
  .test(
    "is-cep",
    "CEP não é valido",
    (value) => validateCep(value)
  ),
  numero_whatsapp: Yup.string().nullable()
  .test(
    "is-numero_whatsapp",
    "Whatsapp não é valido",
    (value) => {
      if(!!value) {
        return validatePhone(value)
      }
      return true;
    }
    ),
  telefone: Yup.string()  
  .test(
    "is-telefone",
    "Fone de contato não é valido",
    (value) => validatePhone(value)
  )
  .required('Fone obrigatório'),
  site: Yup.string().url('URL inválida').nullable(),
  uf_endereco_id: Yup.number().required('Estado obrigatório'),
  porte_empreendimento_id: Yup.number().required('Porte do empreendimento obrigatório'),
  nome_responsavel_legal: Yup.string().required('Nome obrigatório'),
  funcao_responsavel_legal: Yup.string().required('Função obrigatório'),
  cpf_responsavel_legal: Yup.string()
    // .test('valid_cpf', 'CPF inválido', (val) => {
    //   if (
    //     val !== null &&
    //     val !== '' &&
    //     val !== undefined &&
    //     val.length === 11
    //   ) {
    //     return testCPF(val);
    //   }
    //   return true;
    // })
    .test(
      "is-cpf",
      "CPF não é valido",
      (value) => validateCPF(value)
    )
    .required('CPF obrigatório'),
  anexo_cpf_responsavel_legal: Yup.string().required('Anexo do CPF obrigatório'),
  rg_responsavel_legal: Yup.string().required('RG obrigatório'),
  anexo_rg_responsavel_legal: Yup.string().required('Anexo do RG obrigatório'),
  endereco_responsavel_legal: Yup.string().required('Endereço do responável legal obrigatório'),
  anexo_endereco_responsavel_legal: Yup.string().required('Anexo endereço do responável legal obrigatório'),
  telefone_responsavel_legal: Yup.string().required('Fone do representante legal obrigatório')
    .test(
      "is-telefone",
      "Fone do representante legal obrigatório",
      (value) => validatePhone(value)
    ),
  email_responsavel_legal: Yup.string().email().required('E-mail do representante legal obrigatório'),
  confirmacao_email_responsavel_legal: Yup.string()
    .email()
    .required('Confirmação do E-mail do representante legal obrigatório')
    .oneOf([Yup.ref('email_responsavel_legal'), null], 'Email não conferem.'),
  email: Yup.string().email().required('E-mail de acesso obrigatório'),
  confirmacao_email: Yup.string()
    .email()
    .required('Confirmação do E-mail obrigatório')
    .oneOf([Yup.ref('email'), null], 'Email não conferem.'),
  check_termos: Yup.boolean().required('Termos de uso requerido'),
  password: Yup.string().required('Senha obrigatório'),
  confirmacao_senha: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem')
    .required('Senha de confirmação obrigatório'),
});

export default schema;
