import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getProcessoCCLA,
    showProcesso,
} from '../../store/modules/processo/action';
import { useParams } from 'react-router-dom';
import { BlobProvider } from '@react-pdf/renderer';
import CCLAPDF from './PDF';
import NotFoundImg from '~/assets/images/not_found.svg';
import LogoSIBLAM from '~/assets/images/logo.svg';

function CCLAPrint() {
    let { processo_protocolo, processo_id } = useParams();

    const dispatch = useDispatch();
    const { lista_processos_ccla, processo } = useSelector(
        ({ ProcessoReducer }) => ProcessoReducer
    );

    useEffect(() => {
        if ((processo_protocolo, processo_id)) {
            dispatch(getProcessoCCLA(processo_protocolo));
            dispatch(
                showProcesso(processo_id, {
                    with: 'entidade',
                })
            );
        }
    }, [processo_protocolo, processo_id, dispatch]);

    return (
        <>
            {lista_processos_ccla?.data?.length > 0 ? (
                <>
                    <BlobProvider
                        document={
                            <CCLAPDF
                                header={processo}
                                process={lista_processos_ccla.data[0]}
                            />
                        }
                    >
                        {({ blob, url, loading, error }) => {
                            if (loading) {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '160vh',
                                        }}
                                    >
                                        <div
                                            className="error-content text-center"
                                            style={{ fontSize: '12px' }}
                                        >
                                            <h3 className="p-5">
                                                <i
                                                    className="fas fa-hourglass-half"
                                                    style={{
                                                        fontSize: '2em',
                                                        animation:
                                                            'rotate 2s linear infinite',
                                                    }}
                                                />{' '}
                                                AGUARDE ENQUANTO GERA A CADEIA
                                                DE CUSTÓDIA DO LICENCIAMENTO
                                                AMBIENTAL - CCLA.
                                            </h3>
                                            <div>
                                                <i
                                                    className="fas fa-spinner fa-spin"
                                                    style={{
                                                        fontSize: '2em',
                                                        color: '#007bff',
                                                    }}
                                                />
                                            </div>
                                            <img
                                                alt="Loading"
                                                height="auto"
                                                src={LogoSIBLAM}
                                                width="40%"
                                            />
                                        </div>
                                    </div>
                                );
                            }

                            if (blob) {
                                window.location.href = url;
                            }

                            if (error) {
                                return (
                                    <div
                                        className="error-content text-center"
                                        style={{ fontSize: '12px' }}
                                    >
                                        <h3 className="p-5">
                                            <i className="fas fa-exclamation-triangle text-warning" />{' '}
                                            Oops! Algo deu errado.
                                        </h3>
                                        <img
                                            alt="Not Found"
                                            height="auto"
                                            src={NotFoundImg}
                                            width="40%"
                                        />
                                    </div>
                                );
                            }

                            return null;
                        }}
                    </BlobProvider>
                </>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '160vh',
                    }}
                >
                    <div
                        className="error-content text-center"
                        style={{ fontSize: '12px' }}
                    >
                        <h3 className="p-5">
                            <i
                                className="fas fa-hourglass-half"
                                style={{
                                    fontSize: '2em',
                                    animation: 'rotate 2s linear infinite',
                                }}
                            />{' '}
                            Aguarde enquanto estamos reunindo as informações da
                            CCLA.
                        </h3>
                        <div>
                            <i
                                className="fas fa-spinner fa-spin"
                                style={{ fontSize: '2em', color: '#007bff' }}
                            />
                        </div>
                        <img
                            alt="Loading"
                            height="auto"
                            src={LogoSIBLAM}
                            width="40%"
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default CCLAPrint;
