import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal, Form } from 'react-bootstrap';
import { FaList, FaArrowCircleRight } from 'react-icons/fa';
import Select from 'react-select';
import { loadListaAnalistasEntidades } from '~/store/modules/user/action';
import { enviarParaAnalista } from '~/store/modules/processo/action';
import { Loading } from '~/components';

const BtnEnviarAnalista = ({ processo_id, redistribuicao }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [btnSalvar, setBtnSalva] = useState(false);
  const [formData, setFormData] = useState({
    processo_id,
    user_id: undefined,
  });
  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);
  const { user_analistas_entidades } = useSelector(
    ({ UserReducer }) => UserReducer
  );

  useEffect(() => {
    if (processo_id) {
      dispatch(
        loadListaAnalistasEntidades({
          raw_list: true,
          with: 'pessoa_tecnico_profissional',
          where: `entidade_id:${user_auth.user.entidade_id}`
        })
      );
    }
  }, [dispatch, processo_id, user_auth.user.entidade_id]);

  useEffect(() => {
    let optionSet = [];
    if (user_analistas_entidades.data) {
      user_analistas_entidades.data.forEach(
        ({ id, nome, pessoa_tecnico_profissional }) => {
          optionSet.push({
            value: id,
            label: `${nome} - CNPJ: ${pessoa_tecnico_profissional.cnpj}`,
          });
        }
      );
    }
    setOptions(optionSet);
  }, [user_analistas_entidades]);

  useEffect(() => {
    if (formData.user_id) {
      setBtnSalva(true);
    } else {
      setBtnSalva(false);
    }
  }, [formData]);

  const handleSelectAnalista = (event) => {
    setFormData({
      ...formData,
      user_id: event.value,
    });
  };

  const handleHide = () => {
    setShow(false);
    setFormData({
      ...formData,
      user_id: undefined,
    });
  };

  const handleEnviar = async () => {
    try {
      let confirm = await Swal.fire({
        title: 'Enviar Processo?',
        text: 'Deseja Enviar Processo?!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Não',
      });

      if (confirm.value) {
        if (formData.user_id) {
          await dispatch(
            enviarParaAnalista(processo_id, { user_id: formData.user_id })
          );
          await Swal.fire('Sucesso', 'Enviado para o(a) analista com sucesso!', 'success');
          await history.go();
        }
      }
    } catch (error) {
      await Swal.fire('Erro ao enviar', 'Ocorreu um erro ao enviar', 'error');
    }
  };

  return (
    <>
      {processo_id ? (
        <>
          <button
            className="btn btn-success"
            onClick={() => setShow(true)}
          >
            {redistribuicao ? (
              <>
              Redistribuir &nbsp;<FaArrowCircleRight />
              </>
            ): (
              <>
              Enviar para Analista &nbsp;<FaArrowCircleRight />
              </>
            )}
          </button>
          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={handleHide}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {' '}
                <FaList /> Lista de Analista
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Selecione o Analista</Form.Label>
                <Select
                  isLoading={!user_analistas_entidades.data}
                  onChange={handleSelectAnalista}
                  options={options}
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <div className="btn-group">
                <button
                  className="btn btn-default"
                  onClick={handleHide}
                  type="button"
                >
                  <i className="fa fa-window-close" /> Fechar
                </button>

                {btnSalvar && (
                  <button
                    className="btn btn-success"
                    onClick={handleEnviar}
                    type="button"
                  >
                    <FaArrowCircleRight /> Enviar
                  </button>
                )}
              </div>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default BtnEnviarAnalista;
