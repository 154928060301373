import React from 'react';
import {
    Page, Text, View, Document
} from '@react-pdf/renderer';
import styles from './styles';
import { Header, Footer, Row, Col } from '~/components/PDF';
import formatCpfCnpj from '~/utils/formatCpfCnpj';
import { formatData } from '~/utils/formatDataHora';
import formatCep from '~/utils/formatCep';

export default function RAFAPDF({
    header, process
}) {
    console.log('process', process.nome_empreendedor)
    return (
        <Document title="CADEIA DE CUSTÓDIA DO LICENCIAMENTO AMBIENTAL - CCLA">
        <Page size="A4" style={styles.page} wrap>
            <Header
                logoEntity={header.entidade?.logo_base64}
                entityName={header.entidade?.nome}
                organName={header.entidade?.nome_orgao_ambiental}
                LogoCounty={header.entidade?.logo_municipio_base64}
                styles={styles}
            />
            <View style={{ marginTop: '-10px' }}>
                <Text style={[styles.title, { textAlign: 'center' }]}>
                    CADEIA DE CUSTÓDIA DO LICENCIAMENTO AMBIENTAL - CCLA
                </Text>
            </View>
            <View style={styles.section}>
                <View style={{...styles.table}}>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Protocolo da Licença Ambiental
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.licenca}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Nome do empreendedor
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.nome_empreendedor || 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            CNPJ/CPF
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {formatCpfCnpj(process?.cpf || '') || formatCpfCnpj(process?.cnpj || '') || 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Endereço do Empreendimento
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {`${process?.logradouro_pessoa}, ${process?.numero_endereco_pessoa} - ${process?.bairro_pessoa} - `}{formatCep(process?.cep_pessoa || '')}{` - ${process?.cidade_pessoa}` || 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Tipo de Licença Ambiental
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.tipo_licenca || 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Tipo de Atividade
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.nome_atividade || 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            CNAE da Atividade
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.cnae_atividade || 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Data de Protocolo do Processo
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                        {process?.data_protocolo_processo ? formatData(process?.data_protocolo_processo) : 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Data da Distribuição do Processo
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.data_distribuicao_processo
                            ? formatData(process?.data_distribuicao_processo)
                            : 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Data da Vistoria Técnica do Processo
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.data_vistoria_processo
                            ? formatData(process?.data_vistoria_processo)
                            : 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Data de Finalização de Análise do Processo
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.data_finalizacao_processo
                            ? formatData(process.data_finalizacao_processo)
                            : 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Data da Emissão da Licença Ambiental
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.data_emissao_licenca
                            ? formatData(process.data_emissao_licenca)
                            : 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Data da Validade da Licença Ambiental
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.licenca_validade || 'Sem resultado.'}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            Data da Publicação do Recebimento
                        </Col>
                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                            {process?.data_distribuicao_processo
                            ? formatData(process?.data_distribuicao_processo)
                            : 'Sem resultado.'}
                        </Col>
                    </Row>
                </View>
                    {process?.processos_anteriores?.length > 0 && (
                        <>
                            {process?.processos_anteriores.map((process_ant, index) => (
                                <View key={index} style={{ ...styles.table, backgroundColor: index % 2 !== 1 ? '#BFBFBF' : '' }}>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            Tipo de Licença Ambiental
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            {process_ant?.tipo_licenca || 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            Tipo de Atividade
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            {process_ant?.nome_atividade || 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            CNAE da Atividade
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            {process_ant?.cnae_atividade || 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            Data do Protocolo do Processo
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                        {process_ant?.data_protocolo_processo
                                        ? formatData(process_ant?.data_protocolo_processo)
                                        : 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            Data da Distribuição do Processo
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                        {process_ant?.data_distribuicao_processo
                                        ? formatData(process_ant?.data_distribuicao_processo)
                                        : 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            Data da Vistoria Técnica do Processo
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                        {process_ant?.data_vistoria_processo
                                        ? formatData(process_ant?.data_vistoria_processo)
                                        : 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            Data da Finalização de Análise do Processo
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                        {process_ant?.data_finalizacao_processo
                                        ? formatData(process_ant.data_finalizacao_processo)
                                        : 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            Data da Emissão da Licença Ambiental
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                        {process_ant?.data_emissao_licenca
                                        ? formatData(process_ant.data_emissao_licenca)
                                        : 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            Data da Validade da Licença Ambiental
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            {process_ant?.licenca_validade || 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                            Data da Publicação do Recebimento
                                        </Col>
                                        <Col style={[styles.semibold, { fontSize: '10px' }]}>
                                        {process_ant?.data_distribuicao_processo
                                        ? formatData(process_ant?.data_distribuicao_processo)
                                        : 'Sem resultado.'}
                                        </Col>
                                    </Row>
                                </View>
                            ))}
                        </>
                    )}
                
            </View>
            <Footer
                logoSiblam={header.entidade?.src_siblam_logo_base64}
                styles={styles}
            />
        </Page>
        </Document>
    );
}
