import * as actions from './actionType';

export const loadProcessos = (params) => ({
  type: actions.LOAD_PROCESSOS,
  params,
});

export const loadDistribuicao = (params) => ({
  type: actions.LOAD_PROCESSO_DISTRIBUICAO,
  params,
});

export const loadAutorizacao = (params) => ({
  type: actions.LOAD_PROCESSO_AUTORIZACAO,
  params,
});

export const loadListaAnalise = (params) => ({
  type: actions.LOAD_PROCESSO_LISTA_ANALISE,
  params,
});

export const getProcessos = (processos) => ({
  type: actions.PROCESSOS_DATA,
  processos,
});
export const enviarParaAnalista = (id, data) => ({
  type: actions.ENVIAR_PARA_ANALISTA,
  id,
  data,
});
export const storeProcesso = (processo) => ({
  type: actions.STORE_PROCESSO,
  processo,
});
export const getProcesso = (processo) => ({
  type: actions.GET_PROCESSO,
  processo,
});

export const addAtividadeTipoLicenca = (id, data) => ({
  type: actions.ADD_ATIVIDADE_TIPO_LICENCA,
  id,
  data,
});

export const loadTramitesProcesso = (id) => ({
  type: actions.LOAD_TRAMITES_PROCESSO,
  id,
});

export const loadCondicionantesProcesso = (id) => ({
  type: actions.LOAD_CONDICIONANTES_PROCESSO,
  id,
});

export const updateCondicionantesProcesso = (id, data) => ({
  type: actions.UPDATE_CONDICIONANTES_PROCESSO,
  id,
  data,
});

export const getTramitesProcesso = (tramites) => ({
  type: actions.TRAMITES_PROCESSOS_DATA,
  tramites,
});

export const getCondicionantesProcesso = (condicionantes) => ({
  type: actions.CONDICIONANTES_PROCESSOS_DATA,
  condicionantes,
});

export const addPessoaResponsavelTecnico = (id, data) => ({
  type: actions.ADD_PESSOA_RESPONSAVEL_TECNICO,
  id,
  data,
});

export const enviarParaApia = (id) => ({
  type: actions.ENVIAR_PARA_APIA,
  id,
});
export const enviarParaDistribuicao = (id) => ({
  type: actions.ENVIAR_PARA_DISTRIBUICAO,
  id,
});

export const enviarParaAutorizacao = (id) => ({
  type: actions.ENVIAR_PARA_AUTORIZACAO,
  id,
});

export const gerarCobranca = (id) => ({
  type: actions.GERAR_COBRANCA,
  id,
});

export const showProcesso = (id, params) => ({
  type: actions.SHOW_PROCESSO,
  id,
  params,
});

export const getErros = (errors) => ({
  type: actions.PROCESSO_ERROR,
  errors,
});

export const getBoleto = (info) => ({
  type: actions.GET_BOLETO,
  info: info,
});

export const setBoleto = (url) => ({
  type: actions.SET_BOLETO,
  url: url,
});

export const changeStatus = (status) => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

export const finalizarProcesso = (id, data) => ({
  type: actions.FINALIZAR_PROCESSO,
  id,
  data,
});

export const enviarLicenca = (id) => ({
  type: actions.ENVIAR_LICENCA,
  id,
});

export const listaProcessos = () => ({
  type: actions.LISTA_PROCESSOS,
});

export const listaProcessosPorUsuario = (processos) => ({
  type: actions.LISTA_PROCESSOS_POR_USUARIOS,
  processos,
});

export const getProcessoRef = (processo_id) => ({
  type: actions.GET_PROCESSOS_POR_REFERENCIA,
  processo_id,
});

export const processosRef = (processos) => ({
  type: actions.LIST_PROCESSOS_POR_REFERENCIA,
  processos,
});

export const getProcessoCCLA = (protocolo) => ({
  type: actions.GET_PROCESSOS_CCLA,
  protocolo,
});

export const getProcessoCCLAList = (processos) => ({
  type: actions.LISTA_PROCESSOS_CCLA,
  processos,
});
