import React from 'react';
import { TableCustom, TdCustom, ThCustom } from './styles';
import { formatData } from '~/utils/formatDataHora';
import formatCep from '~/utils/formatCep';
import formatCpfCnpj from '~/utils/formatCpfCnpj';

function TableCCLA({ data }) {
  const process = data[0];

  return (
    <>
      <TableCustom>
        <tbody>
          <tr>
            <TdCustom>Protocolo da Licença Ambiental</TdCustom>
            <ThCustom>{process?.licenca}</ThCustom>
          </tr>
          <tr>
            <TdCustom>Nome do empreendedor</TdCustom>
            <ThCustom>
              {process?.nome_empreendedor || 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>CNPJ/CPF</TdCustom>
            <ThCustom>
              {formatCpfCnpj(process?.cpf || '') || formatCpfCnpj(process?.cnpj || '') || 'Sem resultado.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>Endereço do Empreendimento</TdCustom>
            <ThCustom>
              {`${process?.logradouro_pessoa}, ${process?.numero_endereco_pessoa} - ${process?.bairro_pessoa} - `}{formatCep(process?.cep_pessoa || '')}{` - ${process?.cidade_pessoa}` || 'Sem resultado.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>Tipo de Licença Ambiental</TdCustom>
            <ThCustom>
              {process?.tipo_licenca || 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>Tipo de Atividade</TdCustom>
            <ThCustom>
              {process?.nome_atividade || 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>CNAE da Atividade</TdCustom>
            <ThCustom>
              {process?.cnae_atividade || 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>Data de Protocolo do Processo</TdCustom>
            <ThCustom>
              {process?.data_protocolo_processo
                ? formatData(process?.data_protocolo_processo)
                : 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>Data de Distribuição do Processo</TdCustom>
            <ThCustom>
              {process?.data_distribuicao_processo
                ? formatData(process?.data_distribuicao_processo)
                : 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>Data de Vistoria Técnica do Processo</TdCustom>
            <ThCustom>
              {process?.data_vistoria_processo
                ? formatData(process?.data_vistoria_processo)
                : 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>
            Data de Finalização de Análise do Processo
            </TdCustom>
            <ThCustom>
              {process?.data_finalizacao_processo
                ? formatData(process.data_finalizacao_processo)
                : 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>Data da Emissão da Licença Ambiental</TdCustom>
            <ThCustom>
              {process?.data_emissao_licenca
                ? formatData(process.data_emissao_licenca)
                : 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>Data de Validade da Licença Ambiental</TdCustom>
            <ThCustom>
              {process?.licenca_validade || 'Sem resultados.'}
            </ThCustom>
          </tr>
          <tr>
            <TdCustom>Data de Publicação do Recebimento</TdCustom>
            <ThCustom>
              {process?.data_distribuicao_processo
                ? formatData(process?.data_distribuicao_processo)
                : 'Sem resultados.'}
            </ThCustom>
          </tr>
        </tbody>
      </TableCustom>
      {process?.processos_anteriores?.length > 0 && (
        <>
          {process?.processos_anteriores.map((process_ant) => (
            <TableCustom
              key={process_ant?.licenca}
              striped
            >
              <tbody>
                <tr>
                  <TdCustom>Tipo de Licença Ambiental</TdCustom>
                  <ThCustom>
                    {process_ant?.tipo_licenca || 'Sem resultados.'}
                  </ThCustom>
                </tr>
                <tr>
                  <TdCustom>Tipo de Atividade</TdCustom>
                  <ThCustom>
                    {process_ant?.nome_atividade || 'Sem resultados.'}
                  </ThCustom>
                </tr>
                <tr>
                  <TdCustom>CNAE da Atividade</TdCustom>
                  <ThCustom>
                    {process_ant?.cnae_atividade || 'Sem resultados.'}
                  </ThCustom>
                </tr>
                <tr>
                  <TdCustom>Data de Protocolo do Processo</TdCustom>
                  <ThCustom>
                    {process_ant?.data_protocolo_processo
                      ? formatData(process_ant?.data_protocolo_processo)
                      : 'Sem resultados.'}
                  </ThCustom>
                </tr>
                <tr>
                  <TdCustom>Data de Distribuição do Processo</TdCustom>
                  <ThCustom>
                    {process_ant?.data_distribuicao_processo
                      ? formatData(process_ant?.data_distribuicao_processo)
                      : 'Sem resultados.'}
                  </ThCustom>
                </tr>
                <tr>
                  <TdCustom>Data de Vistoria Técnica do Processo</TdCustom>
                  <ThCustom>
                    {process_ant?.data_vistoria_processo
                      ? formatData(process_ant?.data_vistoria_processo)
                      : 'Sem resultados.'}
                  </ThCustom>
                </tr>
                <tr>
                  <TdCustom>
                      Data de Finalização de Análise do Processo
                  </TdCustom>
                  <ThCustom>
                    {process_ant?.data_finalizacao_processo
                      ? formatData(process_ant.data_finalizacao_processo)
                      : 'Sem resultados.'}
                  </ThCustom>
                </tr>
                <tr>
                  <TdCustom>Data da Emissão da Licença Ambiental</TdCustom>
                  <ThCustom>
                    {process_ant?.data_emissao_licenca
                      ? formatData(process_ant.data_emissao_licenca)
                      : 'Sem resultados.'}
                  </ThCustom>
                </tr>
                <tr>
                  <TdCustom>Data de Validade da Licença Ambiental</TdCustom>
                  <ThCustom>
                    {process_ant?.licenca_validade || 'Sem resultados.'}
                  </ThCustom>
                </tr>
                <tr>
                  <TdCustom>Data de Publicação do Recebimento</TdCustom>
                  <ThCustom>
                    {process_ant?.data_distribuicao_processo
                      ? formatData(process_ant?.data_distribuicao_processo)
                      : 'Sem resultados.'}
                  </ThCustom>
                </tr>
              </tbody>
            </TableCustom>
          ))}
        </>
      )}
    </>
  );
}

export default TableCCLA;
