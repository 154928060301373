import { StyleSheet, Font } from '@react-pdf/renderer';
import InterRegular from '~/assets/fonts/Inter/Inter-Regular.ttf';
import InterSemiBold from '~/assets/fonts/Inter/Inter-SemiBold.ttf';
import InterBold from '~/assets/fonts/Inter/Inter-Bold.ttf';

Font.register({
    family: 'Inter',
    fonts: [
        { src: InterRegular },
        { src: InterSemiBold, fontStyle: 'semibold' },
        { src: InterBold, fontStyle: 'bold' },
    ]
});

export default StyleSheet.create({
    page: {
        padding: '30px 50px 70px 50px',
        flexDirection: 'column'
    },
    section: {
        margin: '15px 0px'
    },
    title: {
        fontSize: '13px',
        fontFamily: 'Inter',
        fontStyle: 'bold'
    },
    subtitle: {
        fontSize: '13px',
        fontFamily: 'Inter'
    },
    semibold: {
        fontFamily: 'Inter',
        fontStyle: 'semibold'
    },
    footer: {
        bottom: '20px',
        alignSelf: 'center',
        position: 'absolute'
    },
    pagination: {
        fontFamily: 'Inter',
        fontSize: '11px',
        alignSelf: 'flex-end',
        bottom: '30px',
        position: 'absolute',
        right: '50px'
    },
    table: {
        width: '100%',
        borderTop: '0.5px solid black',
        borderBottom: '0.5px solid black'
    },
});
